<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <h4 v-if="lotplans.length > 1" class="card-title font-weight-light mt-2 ml-2">
        Please select a lot and plan below to continue
      </h4>
      <br v-if="lotplans.length > 1"/>

      <div class="ml-auto text-right">
        <div class="body-3 grey--text font-weight-light" v-text="title" />

        <h3 class="text-h3 font-weight-light text--primary">
          <span v-if="lotplans.length === 1">
            <span>
              [{{ formatLotParcel9999(lotplans[0].Parcel.Lot) }}/{{  lotplans[0].Parcel.Plan }}]
            </span>
          </span>
          <span v-if="lotplans.length > 1">
            <span v-for="(detail, index) in lotplans" :key="index">
              <a class="parcel-link" @click="displayParcel(detail)"
                >[{{ formatLotParcel9999(detail.Parcel.Lot) }}/{{ detail.Parcel.Plan }}] &nbsp;</a
              >
            </span>
          </span>
        </h3>
      </div>
    </template>
  </base-material-card>
</template>

<script>
import Card from "@/components/base/Card";
import { mapState } from "vuex";

export default {  
  name: "AddressCard",

  inheritAttrs: false,
  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  computed: mapState({
    lotplans: (state) => state.address.address.currentAddressLotPlans,
  }),
  methods: {
    displayParcel(parcel) {
      this.$store.dispatch("setCurrentParcel", parcel).then(() => {
        this.$emit("display-parcel");
      });
    },

    formatLotParcel9999(lot) {
      return lot === '9999' ? '0' : lot
    }
  },
};
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
